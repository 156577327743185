import React from "react";
import Layout from "../components/layout";
import { Link } from 'gatsby';
import styled from 'styled-components';

const BlogPostItem = styled.div`
	margin-bottom: 2rem;
`;

const Pagination = styled.nav`
	display: flex;
	justify-content: flex-end;
`;

const PageNumberWrapper = styled.div`
	margin: 0 .25rem;
`;

const PageNumber = styled(Link)`
	background-color: ${props => props.isCurrentPage ? 'blue' : '#f4f4f4'};
	border: 1px solid rgba(0, 0, 0, .01);
	border-radius: 50%;
	color: ${props => props.isCurrentPage ? 'white' : 'blue'};
	display: block;
	font-size: .875rem;
	height: 1.5rem;
	line-height: 1.5rem;
	text-align: center;
	text-decoration: none;
	width: 1.5rem;
`;

export default ({pageContext}) => (
	<Layout>
		{pageContext.posts.map(post => (
			<BlogPostItem key={post.node.wordpress_id}>
				<h3 dangerouslySetInnerHTML={{__html: post.node.title}} />
				<p>{post.node.date}</p>
				<p dangerouslySetInnerHTML={{__html: post.node.excerpt}} />
				<div>
					<Link to={`/blog/${post.node.slug}`}>Continue reading</Link>
				</div>
			</BlogPostItem>
		))}
		<Pagination>
			{Array.from({length: pageContext.numPages}).map((page, index) => (
				<PageNumberWrapper key={index}>
					<PageNumber to={index === 0 ? `/blog` : `/blog/${index + 1}/`} isCurrentPage={index + 1 === pageContext.currentPage}>
						{index + 1}
					</PageNumber>
				</PageNumberWrapper>
			))}
		</Pagination>
	</Layout>
)
